<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-title>
          <span class="headline">Welcome to project</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <div
                  :class="`alert alert-${message.color}`"
                  role="alert"
                  v-if="message.show"
                >
                  {{ message.text }}
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Close
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { REFRESH } from "@/core/services/store/synonyms.module";
import { CHECK_INVITE } from "@/core/services/store/users.module";

export default {
  data: () => ({
    valid: true
  }),
  methods: {
    refresh: function() {
      this.$store.dispatch(REFRESH);
    }
  },
  computed: {
    dialog: {
      get: function() {
        return this.$route.query.hash;
      },
      set: function() {
        this.$router.push({ name: "dashboard" });
      }
    },
    ...mapState({
      message: state => state.message.message
    })
  },
  beforeCreate() {
    if (this.$route.query.hash && this.$route.query.mail) {
      this.$store.dispatch(CHECK_INVITE, {
        hash: this.$route.query.hash,
        user_email: this.$route.query.mail
      });
    }
    this.$store.dispatch(REFRESH);
  }
};
</script>
