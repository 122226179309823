<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">
    <!--begin: Search -->
    <b-dropdown
      v-if="enable"
      size="sm"
      id="kt_quick_search_toggle"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <div class="btn btn-icon btn-clean btn-lg btn-dropdown mr-1">
          <span class="svg-icon svg-icon-xl svg-icon-primary">
            <inline-svg src="media/svg/icons/General/Search.svg" />
          </span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <KTSearchDefault></KTSearchDefault>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: Search -->

    <!--begin: Notifications -->
    <b-dropdown
      v-if="enable"
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <div
          class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-primary"
        >
          <span class="svg-icon svg-icon-xl svg-icon-primary">
            <inline-svg src="media/svg/icons/Code/Compiling.svg" />
          </span>
          <span class="pulse-ring"></span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <form>
          <KTDropdownNotification></KTDropdownNotification>
        </form>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: Notifications -->

    <!--begin: Quick Actions -->
    <b-dropdown
      v-if="enable"
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
          <span class="svg-icon svg-icon-xl svg-icon-primary">
            <inline-svg src="media/svg/icons/Media/Equalizer.svg" />
          </span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <KTDropdownQuickAction></KTDropdownQuickAction>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: Quick Actions -->

    <!--begin: My Cart -->
    <b-dropdown
      v-if="enable"
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
          <span class="svg-icon svg-icon-xl svg-icon-primary">
            <inline-svg src="media/svg/icons/Shopping/Cart3.svg" />
          </span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <KTDropdownMyCart></KTDropdownMyCart>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: My Cart -->

    <!--begin: Quick panel toggle -->
    <KTQuickPanel v-if="enable"></KTQuickPanel>
    <!--end: Quick panel toggle -->

    <date-range-picker
      ref="picker"
      :locale-data="{
        direction: 'ltr',
        format: 'dd. mm yyyy',
        separator: ' - ',
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        monthNames: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ],
        firstDay: 1
      }"
      single-date-picker="range"
      :min-date="calendar.minDate"
      :max-date="calendar.maxDate"
      :opens="calendar.opens"
      v-model="calendar.dateRange"
      :ranges="calendar.ranges"
      :control-container-class="calendar.class"
      @update="onDateRangeChanged"
    >
      <template v-slot:input="picker">
        <div class="input-group" style="min-width: 350px">
          <div class="input-group-prepend">
            <span class="input-group-text"
              ><i class="fa fa-calendar-alt"></i
            ></span>
          </div>
          <div class="form-control">
            {{ picker.startDate | moment }} - {{ picker.endDate | moment }}
          </div>
        </div>
      </template>
      <div slot="footer" slot-scope="data" class="slot container">
        <b-container class="bv-example-row">
          <b-row>
            <b-col class="text-right">
              <button @click="data.clickCancel" class="btn">Close</button>
              <button
                v-if="!data.in_selection"
                @click="data.clickApply"
                class="btn btn-primary"
              >
                Apply
              </button>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </date-range-picker>
    <!--begin: Language bar -->
    <div class="topbar-item">
      <b-dropdown
        size="sm"
        variant="link"
        toggle-class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 text-decoration-none"
        no-caret
        right
        no-flip
      >
        <template v-slot:button-content>
          <img
            class="h-20px w-20px rounded-sm"
            :src="languageFlag || getLanguageFlag"
            alt=""
          />
        </template>
        <b-dropdown-text tag="div" class="min-w-md-175px">
          <KTDropdownLanguage
            v-on:language-changed="onLanguageChanged"
          ></KTDropdownLanguage>
        </b-dropdown-text>
      </b-dropdown>
    </div>
    <!--end: Language bar -->

    <!--begin: User Bar -->
    <KTQuickUser></KTQuickUser>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import cookie from "@/assets/js/components/cookie";
import DateRangePicker from "vue2-daterange-picker";
import KTSearchDefault from "@/view/layout/extras/dropdown/SearchDefault.vue";
import KTDropdownNotification from "@/view/layout/extras/dropdown/DropdownNotification.vue";
import KTDropdownQuickAction from "@/view/layout/extras/dropdown/DropdownQuickAction.vue";
import KTDropdownMyCart from "@/view/layout/extras/dropdown/DropdownMyCart.vue";
import KTDropdownLanguage from "@/view/layout/extras/dropdown/DropdownLanguage.vue";
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
import KTQuickPanel from "@/view/layout/extras/offcanvas/QuickPanel.vue";
import i18nService from "@/core/services/i18n.service.js";

import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import { mapGetters } from "vuex";
import { SET_DATE_RANGE } from "@/core/services/store/date_range.module";

export default {
  name: "KTTopbar",
  data() {
    return {
      enable: false,
      languageFlag: "",
      date: new Date(),
      languages: i18nService.languages,
      picker: { startDate: new Date(), endDate: new Date() },
      calendar: {
        direction: "ltr",
        opens: "left",
        singleDatePicker: true,
        format: "yyyy-mm-dd",
        separator: " - ",
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        monthNames: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ],
        firstDay: 0,
        class: "topbar-item",
        minDate: new Date().setDate(new Date().getDate() - 91),
        maxDate: new Date().setDate(new Date().getDate() - 1),
        ranges: {
          Yesterday: [new Date(), new Date()],
          "Last 3 days": [
            new Date(Date.now() - 3 * 24 * 60 * 60 * 1000),
            new Date(Date.now() - 24 * 60 * 60 * 1000)
          ],
          "Last 7 days": [
            new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
            new Date(Date.now() - 24 * 60 * 60 * 1000)
          ],
          "Last 30 days": [
            new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
            new Date(Date.now() - 24 * 60 * 60 * 1000)
          ],
          "Last 60 days": [
            new Date(Date.now() - 60 * 24 * 60 * 60 * 1000),
            new Date(Date.now() - 24 * 60 * 60 * 1000)
          ],
          "Last 90 days": [
            new Date(Date.now() - 90 * 24 * 60 * 60 * 1000),
            new Date(Date.now() - 24 * 60 * 60 * 1000)
          ]
        },
        dateRange: {
          startDate: cookie.getCookie("startDate")
            ? new Date(moment(cookie.getCookie("startDate")).format())
            : new Date().setDate(new Date().getDate() - 8),
          endDate: cookie.getCookie("endDate")
            ? new Date(moment(cookie.getCookie("endDate")).format())
            : new Date().setDate(new Date().getDate() - 1)
        }
      }
    };
  },
  filters: {
    moment: function(date) {
      return moment(date).format("DD. MM YYYY");
    }
  },
  components: {
    KTSearchDefault,
    KTDropdownNotification,
    KTDropdownQuickAction,
    KTDropdownMyCart,
    KTDropdownLanguage,
    KTQuickUser,
    KTQuickPanel,
    DateRangePicker
  },
  methods: {
    moment: function() {
      return moment();
    },
    onLanguageChanged() {
      this.languageFlag = this.languages.find(val => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    },
    onDateRangeChanged: function(event) {
      cookie.setCookie("startDate", moment(event.startDate).format());
      cookie.setCookie("endDate", moment(event.endDate).format());
      this.$store.dispatch(SET_DATE_RANGE, event);
    }
  },
  computed: {
    getLanguageFlag() {
      return this.onLanguageChanged();
    },
    ...mapGetters(["dateRange"])
  },
  created() {
    if (cookie.getCookie("startDate") && cookie.getCookie("endDate")) {
      this.$store.dispatch(SET_DATE_RANGE, {
        startDate: moment(cookie.getCookie("startDate")).format(),
        endDate: moment(cookie.getCookie("endDate")).format()
      });
    }
  }
};
</script>
