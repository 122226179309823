<template>
  <v-dialog v-model="showDialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
      >
        <a
          class="menu-link menu-toggle"
          v-on="on"
          @click.prevent="refresh"
          href="#"
          ><span class="menu-text">New Sales Channel</span>
          <i class="menu-arrow"></i
        ></a>
      </li>
    </template>
    <v-card v-if="this.loading">
      <v-card-text style="text-align:center">
        <div v-if="this.loading">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            style="margin:auto;background:#fff;display:block;"
            width="200px"
            height="200px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
          >
            <g transform="translate(20 50)">
              <circle cx="0" cy="0" r="6" fill="#7b0000">
                <animateTransform
                  attributeName="transform"
                  type="scale"
                  begin="-0.375s"
                  calcMode="spline"
                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                  values="0;1;0"
                  keyTimes="0;0.5;1"
                  dur="1s"
                  repeatCount="indefinite"
                ></animateTransform>
              </circle>
            </g>
            <g transform="translate(40 50)">
              <circle cx="0" cy="0" r="6" fill="#c34c37">
                <animateTransform
                  attributeName="transform"
                  type="scale"
                  begin="-0.25s"
                  calcMode="spline"
                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                  values="0;1;0"
                  keyTimes="0;0.5;1"
                  dur="1s"
                  repeatCount="indefinite"
                ></animateTransform>
              </circle>
            </g>
            <g transform="translate(60 50)">
              <circle cx="0" cy="0" r="6" fill="#fd7f64">
                <animateTransform
                  attributeName="transform"
                  type="scale"
                  begin="-0.125s"
                  calcMode="spline"
                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                  values="0;1;0"
                  keyTimes="0;0.5;1"
                  dur="1s"
                  repeatCount="indefinite"
                ></animateTransform>
              </circle>
            </g>
            <g transform="translate(80 50)">
              <circle cx="0" cy="0" r="6" fill="#ffbea0">
                <animateTransform
                  attributeName="transform"
                  type="scale"
                  begin="0s"
                  calcMode="spline"
                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                  values="0;1;0"
                  keyTimes="0;0.5;1"
                  dur="1s"
                  repeatCount="indefinite"
                ></animateTransform>
              </circle>
            </g>
          </svg>
        </div>
        <p class="text-dark">
          Great! We are prepared some features for your awesome project
          {{ this.sales_channel.name }}.
        </p>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-title v-if="this.need_sales_channel">
          <span class="headline">Welcome in UpSearch</span>
        </v-card-title>
        <v-card-title v-else>
          <span class="headline">Creating a new project</span>
        </v-card-title>
        <v-card-text>
          <div
            :class="`alert alert-${message.color}`"
            role="alert"
            v-if="message.show"
          >
            {{ message.text }}
          </div>

          <v-container v-if="this.invites.length > 0">
            <v-row>
              <v-col cols="12">
                <span class="headline">You are invited</span>
              </v-col>
            </v-row>
            <v-row v-for="(invite, i) in this.invites" :key="i">
              <v-col cols="4">{{ invite.sales_channel_name }}</v-col>
              <v-col cols="4">{{ invite.invite_user }}</v-col>

              <v-col cols="2">
                <v-btn color="primary" @click="accept(invite)">Accept</v-btn>
              </v-col>
              <v-col cols="2">
                <v-btn color="error" @click="decline(invite)">Decline</v-btn>
              </v-col>
            </v-row>
          </v-container>
          <v-container>
            <v-row v-if="this.invites.length > 0">
              <v-col cols="12" v-if="this.invites.length > 0">
                <span class="headline">Or create new</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="sales_channel.name"
                  required
                  :rules="[v => !!v || 'Item is required']"
                  label="Project name *"
                  ref="f_name"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="sales_channel.website"
                  required
                  :rules="[rules.required, rules.url]"
                  label="Project URL *"
                ></v-text-field>
                <p>Copy URL of your e-shop here 🤓</p>
              </v-col>
            </v-row>
            <v-row v-if="projects.length > 0">
              <v-col cols="8">
                <v-select
                  v-model="sales_channel.copy_by"
                  :items="projects"
                  :item-text="item => item.name"
                  item-value="id"
                  filled
                  label="Kopie z jiného projektu"
                  dense
                >
                </v-select>
                <p>
                  The new project will have all feeds, collections, schemas,
                  result settings and relevance tuning copied. 🤓
                </p>
              </v-col>
              <v-col cols="4">
                <v-btn
                  v-if="sales_channel.copy_by != null"
                  color="blue darken-2"
                  @click.prevent="sales_channel.copy_by = null"
                  text
                >
                  without copy
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="sales_channel.copy_by == null">
              <v-col cols="12">
                <v-select
                  v-model="sales_channel.language_id"
                  :items="languages"
                  :item-text="item => item.name + ' (' + item.code + ')'"
                  item-value="id"
                  filled
                  label="Project language"
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row v-if="sales_channel.copy_by == null">
              <v-col cols="12">
                <v-select
                  v-model="sales_channel.source"
                  :items="sources"
                  :item-text="item => item.name"
                  item-value="key"
                  filled
                  label="Platforma zdroje dat"
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <small>*indicates required field</small>
          </v-container>
        </v-card-text>
        <v-card-actions v-if="this.need_sales_channel">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click.prevent="logout"
            >Logout
          </v-btn>
          <v-btn color="blue darken-1" ref="submit" @click.prevent="save" text>
            Save
          </v-btn>
          <v-btn
            v-if="this.allow_skip"
            color="purple darken-2"
            ref="submit"
            @click.prevent="skip"
            text
          >
            Skip new Project
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click.prevent="close"
            >Close
          </v-btn>
          <v-btn color="blue darken-1" ref="submit" @click.prevent="save" text>
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import {
  CHANGE_SALES_CHANNEL,
  POST_SALES_CHANNEL,
  PULL_SALES_CHANNELS,
  REFRESH
} from "@/core/services/store/project.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module";
import { LOGOUT, VERIFY_AUTH } from "@/core/services/store/auth.module";
import {
  ACCEPT_INVITE,
  DECLINE_INVITE,
  GET_MY_INVITES
} from "@/core/services/store/users.module";

export default {
  data() {
    return {
      allow_skip: false,
      sales_channel: { name: "" },
      valid: true,
      invites: [],
      sources: [
        {
          name: "Shoptet",
          key: "shoptet"
        },
        {
          name: "Custom",
          key: "custom"
        },
        {
          name: "Google",
          key: "google"
        },
        {
          name: "Heureka",
          key: "heureka"
        },
        {
          name: "Praguebest",
          key: "mastershop"
        },
        {
          name: "Praguebest API",
          key: "mastershop-api"
        }
      ],
      rules: {
        required: value => !!value || "Required.",
        counter: value => value.length <= 20 || "Max 20 characters",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        url: value => {
          const pattern = /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/;
          return pattern.test(value) || "Invalid website url.";
        }
      },
      dialog: false
    };
  },
  methods: {
    accept: function(invite) {
      this.allow_skip = true;
      this.$store
        .dispatch(ACCEPT_INVITE, {
          user_email: invite.email,
          hash: invite.hash
        })
        .then(() => {
          this.$store.dispatch(GET_MY_INVITES).then(data => {
            this.invites = data;
          });
        });
    },
    decline: function(invite) {
      this.$store
        .dispatch(DECLINE_INVITE, {
          user_email: invite.email,
          hash: invite.hash
        })
        .then(() => {
          this.$store.dispatch(GET_MY_INVITES).then(data => {
            this.invites = data;
          });
        });
    },
    refresh: function() {
      this.$store.dispatch(REFRESH);
    },
    close: function() {
      if (!this.need_sales_channel) {
        this.dialog = false;
      }
    },
    logout: function() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch(POST_SALES_CHANNEL, this.sales_channel)
          .then(data => {
            this.dialog = false;
            this.need_sales_channel = false;
            this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
            setTimeout(() => {
              this.$store.dispatch(CHANGE_SALES_CHANNEL, data.id);
              this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
              this.$router.push({ name: "dashboard" });
            }, 2000);
          });
      }
    },
    skip() {
      this.$store.dispatch(VERIFY_AUTH);
      this.dialog = false;
      this.need_sales_channel = false;
      this.$store.dispatch(PULL_SALES_CHANNELS);
      this.$router.push({ name: "dashboard" });
    }
  },
  computed: {
    showDialog: {
      get: function() {
        return this.dialog || this.need_sales_channel;
      },
      set: function(value) {
        this.dialog = value;
      }
    },
    ...mapState({
      errors: state => state.project.errors,
      message: state => state.project.message,
      loading: state => state.project.loading,
      hasMessage: state => state.project.hasMessage,
      projects: state => state.project.projects,
      languages: state => state.project.languages,
      need_sales_channel: state => state.auth.needSalesChannel
    })
  },
  mounted() {
    this.$store.dispatch(REFRESH);
    this.sales_channel.name = "";
    this.$store.dispatch(GET_MY_INVITES).then(data => {
      this.invites = data;
    });
  }
};
</script>
