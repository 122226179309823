<template>
  <!-- begin::Page loader -->
  <div class="content-loader">
    <img
      alt="Logo"
      src="https://i.pinimg.com/originals/49/23/29/492329d446c422b0483677d0318ab4fa.gif"
    />
  </div>
  <!-- end::Page Loader -->
</template>

<script>
export default {
  name: "ContentLoader"
};
</script>
