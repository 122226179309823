<template>
  <ul class="menu-nav">
    <li
      aria-haspopup="true"
      data-menu-toggle="click"
      class="menu-item menu-item-submenu menu-item-open-dropdown"
      v-bind:class="{ 'menu-item-active': hasActiveChildren('/custom') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text">{{ currentProject.name }}</span>
      </a>
      <div class="menu-submenu menu-submenu-classic">
        <ul class="menu-subnav">
          <add_dialog />
        </ul>
        <ul class="menu-subnav">
          <li
            v-for="project in projects"
            :key="project.id"
            :class="currentProject.id === project.id ? 'menu-item-active' : ''"
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item menu-item-submenu"
          >
            <a
              href="#"
              class="menu-link menu-toggle"
              v-on:click="changeProject"
              v-bind:data-id="project.id"
              ><span class="menu-text">{{ project.name }}</span></a
            >
          </li>
        </ul>
      </div>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
import { mapState } from "vuex";

import {
  CHANGE_SALES_CHANNEL,
  PULL_SALES_CHANNELS
} from "../../../core/services/store/project.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "../../../core/services/store/htmlclass.module";
import add_dialog from "@/view/pages/project/add_dialog";
import { PULL_PERSONAL_INFO } from "../../../core/services/store/profile.module";

export default {
  components: { add_dialog },
  mounted() {
    this.reloadData();
  },
  name: "KTMenu",
  watch: {
    _changeProject() {
      this.reloadData();
    }
  },
  methods: {
    reloadData() {
      this.$store.dispatch(PULL_SALES_CHANNELS);
    },
    changeProject: function(event) {
      const selectId = event.currentTarget.getAttribute("data-id");
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      // dummy delay
      event.currentTarget.getAttribute("data-id");
      setTimeout(() => {
        // send register request
        this.$store.dispatch(CHANGE_SALES_CHANNEL, selectId);
        this.$store.dispatch(PULL_PERSONAL_INFO);
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      }, 2000);
    },
    addProject: function(event) {
      const selectId = event.currentTarget.getAttribute("data-id");
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      // dummy delay
      event.currentTarget.getAttribute("data-id");
      setTimeout(() => {
        // send register request
        this.$store.dispatch(CHANGE_SALES_CHANNEL, selectId);

        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      }, 2000);
    },
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  },
  computed: {
    _changeProject() {
      return this.currentProject.token;
    },
    ...mapState({
      projects: state => state.project.projects
    }),
    ...mapGetters(["currentProject", "currentProjects"])
  }
};
</script>
